var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "section-header text-center mb-12" },
    [
      _c("div", {
        staticClass: "text-uppercase caption mb-4 grey--text text--darken-2",
        domProps: { textContent: _vm._s(_vm.header) }
      }),
      _c("h2", {
        staticClass: "display-1 font-weight-light",
        domProps: { textContent: _vm._s(_vm.subHeader) }
      }),
      _c("v-divider", { staticClass: "mt-5 mb-4 mx-auto" }),
      _c("p", {
        staticClass: "title font-weight-light mx-auto",
        staticStyle: { "max-width": "700px" },
        domProps: { textContent: _vm._s(_vm.text) }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }